import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import SortIcon from '../../assets/icons/Sort.svg';
import { Span } from '../../globalStyles';
import { sampleColumns } from '../../utility/constants';
import Loader from '../Loader/Loader';
import { Table, Td, Th, Tr } from './Table.styles';

const TableComp = (props) => {
  const { columns = sampleColumns, loading, data, handleNext, error, paginationDetails } = props;
  const [activeSortCol, setActiveSortCol] = useState('company');
  const [isAsc, setIsAsc] = useState(true);

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && Object.values(data).length < paginationDetails.count) {
      handleNext({
        sortBy: activeSortCol,
        isAsc
      });
    }
  };

  const observer = useRef();
  const loader = useCallback(
    (node) => {
      if (loading || error) return;
      const option = {
        root: null,
        rootMargin: '300px',
        threshold: 1.0
      };
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(handleObserver, option);
      if (node) observer.current.observe(node);
    },
    [handleNext, loading, handleObserver]
  );
  const getValue = (val, isTrueLabel, isFalseLabel, column) => {
    const { key, type, unit } = column;
    let value = '';
    // parsing the value
    if (typeof val === 'boolean') {
      value = val ? isTrueLabel : isFalseLabel;
    } else if (type === 'number') {
      let fixTo = 1;
      if (key === 'price') {
        fixTo = 2;
      } else if (key === 'totalRevenue' || key === 'grossProfits') {
        fixTo = 2;
      }
      value = val ? Number(val)?.toFixed(fixTo) : '-';
    } else {
      value = val || '-';
    }
    // adding unit
    if (value !== '-' && unit === '$') {
      value = `$${value}`;
    } else if (value !== '-' && unit === '%') {
      value = `${value}%`;
    } else if (value !== '-' && (unit === 'M' || unit === 'B')) {
      value = `$ ${value}${unit}`;
    }
    return value;
  };
  const handleSort = (col) => {
    let isAscLocal = isAsc;
    if (col === activeSortCol) {
      // toggle the icon
      isAscLocal = !isAsc;
    } else {
      isAscLocal = true;
    }
    setIsAsc(isAscLocal);
    setActiveSortCol(col);
    handleNext({
      pageNoParam: 0,
      sortBy: col,
      isAsc: isAscLocal
    });
  };
  return (
    <>
      <Table className='zebra_strip'>
        {/* row header */}
        <thead>
          <Tr>
            {columns.map((column) => {
              const { align, key, label, id, isSortable } = column;
              // console.log('id', id, key);
              return (
                <Th
                  id={id}
                  key={id}
                  className={`zebra_strip ${align}`}
                  onClick={() => handleSort(key)}
                  style={{ cursor: 'pointer' }}
                >
                  {label}
                  <span>
                    {isSortable ? (
                      <Image
                        src={activeSortCol === key ? SortIcon : SortIcon}
                        width='11px'
                        height='6px'
                        className={`${activeSortCol === key && isAsc ? 'rotate-180' : ''}`}
                      />
                    ) : (
                      ''
                    )}
                  </span>
                </Th>
              );
            })}
          </Tr>
        </thead>
        {/* data rows  */}
        <tbody>
          {Object.values(data || {}).length ? (
            Object.values(data || {}).map((item, index) => {
              // console.log('id', item.id, item.key);
              return (
                <Tr key={item.id}>
                  {columns.map((column) => {
                    const { key, isTrue, isFalse, align } = column;
                    return (
                      <Td
                        className={`zebra_strip ${align} ${activeSortCol === key ? 'enable' : ''}`}
                        key={key}
                        id={key}
                      >
                        {getValue(item?.[key], isTrue, isFalse, column)}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })
          ) : (
            <>
              {(!loading || error) && (
                <Tr className='noHover'>
                  <Td colSpan={columns?.length}>
                    <Span>{error?.message || 'No Items Found'}</Span>
                  </Td>
                </Tr>
              )}
            </>
          )}
          {loading && (
            <Tr className='noHover'>
              <Td colSpan={columns?.length}>
                <Loader />
              </Td>
            </Tr>
          )}
        </tbody>
      </Table>
      <div ref={loader}></div>
    </>
  );
};

export default TableComp;

export const Image = styled.img`
  &.rotate-180 {
    transform: rotate(-180deg);
  }
`;
