import React, { useRef, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import DropdownModal from '../../Components/Dropdown/DropdownModal';
import Loader from '../../Components/Loader/Loader';
import { fontFamily } from '../../constants/font';
import { FlexContainer } from '../../globalStyles';
import useGraphWidthHeight from '../../shared/hooks/useGraphWidthHeight';
import { units } from '../../utility/constants';
import { getQuartiles } from '../../utility/quartileAnalysis';
import UnderlineHeader from '../../utility/underlineHeader';
import {
	ChartHeader,
	DropDownsWrap,
	Header,
	HeadingText,
	OverviewChartDiv,
	ToopltipP,
	Wrapper
} from '../Overview/Overview.style';
import { AxisSpan, Container, Div, DropDownLabel, LoaderDiv } from './Analysis.style';

const box = (i) => (
  <span
    style={{
      width: '2.2rem',
      height: '2.2rem',
      display: 'inline-block',
      position: 'relative',
      background: `${i === 1 ? '#4A5679' : '#819FB8'}`,
      top: '2px'
    }}
  />
);
const dataForDropDown = [
  {
    id: 1,
    label: 'Y-axis',
    key: 'xAxis',
    data: [
      { label: 'B2C vs B2B', xValue: 'isB2B' },
      { label: 'Vertical vs Horizontal', xValue: 'isHorizontal' },
      { label: 'Preventive vs Curative', xValue: 'isPreventive' }
    ]
  },
  {
    id: 2,
    label: 'X-axis',
    key: 'yAxis',
    data: [
      { label: 'Growth Rate', yValue: 'revenueGrowth', tickCount: 10, interval: 1 },
      { label: 'Revenue Multiple', yValue: 'enterpriseToRevenue', tickCount: 10, interval: 1 },
      { label: 'Gross Margin', yValue: 'grossMargins', tickCount: 10, interval: 1 }
    ]
  }
];
const KeyBussinessModelMetrics = ({ plotData = [], loading }) => {
  const [dropdownVal, setDropdownVal] = useState({});
  const { xValue: group, label: xLabel } = dropdownVal?.xAxis || dataForDropDown[0].data[0];
  const { yValue: metric, label: yLabel } = dropdownVal?.yAxis || dataForDropDown[1].data[0];
  let tickCount = 5;
  let domain = ['auto', 'auto'];
  // if (group === 'isB2B') {
  //   if (metric === 'revenueGrowth') {
  //     tickCount = 5;
  //     domain = [-150, 250];
  //   } else if (metric === 'enterpriseToRevenue') {
  //     tickCount = 4;
  //     domain = [-25, 125];
  //   } else if (metric === 'grossMargins') {
  //     tickCount = 5;
  //     domain = [-25, 75];
  //   }
  // } else if (group === 'isHorizontal') {
  //   if (metric === 'revenueGrowth') {
  //     tickCount = 5;
  //     domain = [-100, 200];
  //   } else if (metric === 'enterpriseToRevenue') {
  //     tickCount = 6;
  //     domain = [-100, 150];
  //   } else if (metric === 'grossMargins') {
  //     tickCount = 5;
  //     domain = [-25, 75];
  //   }
  // } else if (group === 'isPreventive') {
  //   if (metric === 'revenueGrowth') {
  //     tickCount = 5;
  //     domain = [-100, 200];
  //   } else if (metric === 'enterpriseToRevenue') {
  //     tickCount = 5;
  //     domain = [-50, 150];
  //   } else if (metric === 'grossMargins') {
  //     tickCount = 7;
  //     domain = [-40, 80];
  //   }
  // }
  const chartBoxRef = useRef();
  const { widthOf, heightOf } = useGraphWidthHeight(chartBoxRef);
  const grp1 = plotData?.filter((e) => e?.[group]);
  const grp2 = plotData?.filter((e) => !e?.[group]);
  const set1 = getQuartiles(grp1, metric);
  const set2 = getQuartiles(grp2, metric);
  const categories = ['top', 'medium', 'bottom'];
  let group1 = 'B2B';
  let group2 = 'B2C';
  if (group === 'isB2B') {
    group1 = 'B2B';
    group2 = 'B2C';
  } else if (group === 'isHorizontal') {
    group1 = 'Horizontal';
    group2 = 'Vertical';
  } else if (group === 'isPreventive') {
    group1 = 'Preventive';
    group2 = 'Curative';
  }
  const data = categories?.map((e) => ({
    [group1]: set1?.[e],
    [group2]: set2?.[e],
    category: e
  }));
  const handleDropdown = (item, property) => {
    setDropdownVal((prev) => ({
      ...prev,
      [property]: item
    }));
  };
  return (
    <Container className='keyBuissnes'>
      <Div className='buttom-space'>
        <Header className='space-between'>
          <ChartHeader style={{ width: 'auto' }}>
            <HeadingText>
              <UnderlineHeader header='Key Business Model Metrics' noOfLastWords={2} />
            </HeadingText>
          </ChartHeader>
          <Wrapper className='chartWrapper'>
            {dataForDropDown &&
              dataForDropDown?.map((e, i) => {
                const isXaxis = e.key === 'xAxis';
                return (
                  <DropDownsWrap key={e.id}>
                    <DropDownLabel style={{ textTransform: 'capitalize' }}>{e.label}</DropDownLabel>
                    <DropdownModal
                      data={e.data}
                      header={isXaxis ? xLabel : yLabel}
                      property={e.key}
                      setDropdownVal={handleDropdown}
                      width={'30rem'}
                    />
                  </DropDownsWrap>
                );
              })}
          </Wrapper>
        </Header>
      </Div>
      <OverviewChartDiv className='keybuisnessmodule' ref={chartBoxRef} style={{ marginTop: '2rem', height: 'auto' }}>
        {loading && (
          <LoaderDiv>
            <Loader />
          </LoaderDiv>
        )}
        <YAxisLabel className='keybuisness'>
          {[group1, group2]?.map((ele, i) => (
            <React.Fragment key={i}>
              {box(i)}
              <span>&nbsp;</span>
              {ele}
              <span>&nbsp;&nbsp;</span>
              {i === 0 && ' vs '}
              <span>&nbsp;&nbsp;</span>
            </React.Fragment>
          ))}
        </YAxisLabel>
        <BarChart
          width={widthOf}
          height={widthOf > 768 ? heightOf + 20 : heightOf - 50}
          data={data}
          layout='vertical'
          margin={{ top: 0, right: 20, bottom: 10, left: 30 }}
          barGap={10}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip
            wrapperStyle={{
              outline: 'none',
              background: '#ffffff 0% 0% no-repeat padding-box',
              boxShadow: '0px 0px 25px #00000026',
              border: '0.5px solid #a0a0a0',
              padding: '5px 5px',
              minWidth: 180
            }}
            content={<CustomTooltip unit={units[metric]} />}
          />
          <XAxis
            tickFormatter={(p) => `${Number(p).toFixed(0)}${units[metric]}`}
            type='number'
            tickCount={tickCount}
            domain={domain}
          />
          <YAxis
            dataKey='category'
            type='category'
            angle={-90}
            textAnchor='middle'
            tickMargin={10}
            style={{ textTransform: 'capitalize' }}
          />
          <Legend />
          <Bar dataKey={group1} fill='#819FB8' barSize={35} />
          <Bar dataKey={group2} fill='#384355' barSize={35} />
        </BarChart>
      </OverviewChartDiv>
      <FlexContainer className='jc_center'>
        <AxisSpan className='keyBuisness'>{dropdownVal?.yAxis ? dropdownVal?.yAxis?.label : 'Growth Rate'}</AxisSpan>
      </FlexContainer>
    </Container>
  );
};

export default KeyBussinessModelMetrics;

function CustomTooltip({ plotData, payload, label, active, unit }) {
  if (active) {
    return (
      <div className='custom-tooltip' style={{ padding: '1rem' }}>
        <ToopltipP style={{ color: '#AE0826' }} className='labelName'>
          {payload[0]?.payload?.category}
        </ToopltipP>

        {payload?.map((ele) => (
          <FlexContainer key={ele.dataKey} FlexContainer style={{ justifyContent: 'space-between' }}>
            <ToopltipP className='labelName'>{ele.name}</ToopltipP>
            <span style={{ width: '4rem' }}>&nbsp;</span>
            <ToopltipP style={{ color: 'black' }} className='labelName'>
              {`${Number(ele.value).toFixed(2)}${unit}`}
            </ToopltipP>
          </FlexContainer>
        ))}
      </div>
    );
  }

  return null;
}

export const YAxisLabel = styled.div`
  transform: rotate(-90deg);
  font-size: 1.8rem;
  position: absolute;
  left: -9rem;
  color: #707070;
  letter-spacing: 1.7px;
  font-weight: bold;
  font-family: ${fontFamily.interVariableFont};

  &.keybuisness {
    left: -21rem;
    position: absolute;
    width: 50rem;
    text-align: center;
  }
`;
