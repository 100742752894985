import { createGlobalStyle } from 'styled-components';
import { device, minDevice } from '../constants';

export const GlobalStyles = createGlobalStyle`

html {
    height: 100%;
  }
  body {
    height: 100%;
  }
  * {
    font-size: 10px;
    // 2560px - 1919px
    @media ${device.desktop} and ${minDevice.laptopXL} {
      font-size: 10px;
    }
    // 1920px - 1439px
    @media ${device.laptopXL} and ${minDevice.laptopL} {
      font-size: 8px;
    }
    // 1440px - 767px
    @media ${device.laptopL} and ${minDevice.tablet} {
      font-size: 7px;
    }
    //768px-424px
    @media ${device.tablet} and ${minDevice.mobileL} {
      font-size: 5px;
    }
    //425px-320px
    @media ${device.mobileL} and ${minDevice.mobileM} {
      font-size: 6px;
    }

    @media ${device.mobileM} and ${minDevice.mobileS} {
      font-size: 6px;
    }
    }
`;
