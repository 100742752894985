import styled, { css } from 'styled-components';
import { fontFamily } from '../../constants/font';
import { fontSize } from '../../utility/constants';

export const Tr = styled.tr`
  box-shadow: -5px -5px 11px #fffffff5, 5px 5px 10px #0000000d;
  min-height: 100px;

  &.header {
    box-shadow: none;
    border-radius: 0;
  }

  &.outLineOnSave {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 2px, rgb(51, 51, 51) 0px 0px 2px 2px;
  }

  &.child {
    padding-left: 3rem;
  }

  & > th:last-child {
    border-right: 0;
  }

  position: relative;
  &.nested {
    display: none;
  }

  &.shouldDelete > td {
    text-decoration: line-through;
  }

  &.showOutline {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 2px, rgb(51, 51, 51) 0px 0px 2px 2px;
  }

  &.strapedTable {
    & > td:not(:nth-last-of-type(-n + 2)) {
      border-right: 1px dashed #e4e5eb;
      &::before {
        content: '';
        height: 2rem;
        width: 1px;
        border-right: 1px dashed #e4e5eb;
        position: absolute;
        right: -1px;
        top: -14px;
      }

      &::after {
        content: '';
        height: 2rem;
        width: 1px;
        border-right: 1px dashed #e4e5eb;
        position: absolute;
        right: -1px;
        bottom: -14px;
      }
    }
  }
`;

export const Th = styled.th`
  position: relative;
  font-size: ${fontSize.text};
  color: 4A4A4A;

  border-right: ${({ noBorder }) => (noBorder ? 0 : `1px dashed #cbcbcf`)};
  border-bottom: 1px solid #cbcbcf;
  padding: 0px 5px;
  & > * {
    font-size: ${fontSize.text};
  }

  &.strapedTable {
    height: 6rem;
  }

  &.zebra_strip {
    height: 9rem;
    background: #384355;
    color: #ffff;
    border-color: #606877;
    text-transform: capitalize;
    font-family: ${fontFamily.interVariableFont};
    font-size: 1.6rem;
    padding-bottom: none;
  }

  & .sortBy {
    position: absolute;
    right: 0.5rem;
    bottom: 1rem;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
`;

export const Td = styled.td`
  font-size: 1.8rem;
  color: #000000;
  padding: 1rem 1rem;

  border-right: ${({ noBorder }) => (noBorder ? '0px solid black' : `1px dashed #cbcbcf`)};
  position: relative;
  text-align: center;

  & p {
    font-size: ${fontSize.text};
  }

  &.showOutline {
    // border cells to display comparision
    border: ${({ showOutline, isOldItem }) =>
      showOutline ? `5px solid ${isOldItem ? '#F70F0F' : '#00D5FF'}` : ''} !important;
  }
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color} !important;
    `};
`;

export const Table = styled.table`
  &.nested {
    width: 90%;
    position: absolute;
    border-spacing: 0rem 2rem;
  }
  border-collapse: separate;
  border-spacing: 0 2rem;

  &.zebra_strip {
    width: 100%;
    border-spacing: 0rem;
    tr {
      border: 1px solid #a0a0a0;
      background: (183,183,183,0);
      box-shadow: none;
      border-radius: none;
      border: none;
      transition: all 0.22s ease-out;
      height: 7rem;
      font-size: 1.8rem;
    }



    tr:hover {
      background-color: #384355;
       td {
        color: white;
      }
    }

    tr:nth-child(odd) {
      background:rgba( 27, 76, 133, 0.1);
      height:7rem;
      :hover {
        td {
          background-color: #384355;
          color: white;
        }
      }
    }

    tr:nth-child(odd).noHover {
      :hover {
        td {
          background: transparent;
          color: white;
        }
      }
    }

    td {
      color: #707070;
      font-size: 1.8rem;
      font-family: ${fontFamily.interVariableFont};
      font-weight: bold;
      border-bottom: none;
      &.enable {
        color: rgb(1, 80, 137);
      }
    }


  &.noBorder {
    & th,
    td {
      border-right: 0px solid;
      text-align: left;
    }
  }

  &.attached {
    border-spacing: 0px;

    & p {
      font-size: 1.8rem;
    }

    & .clip {
      display: block;
      height: 100%;
      overflow-y: auto;
    }

    & tr {
      border-bottom: 2px solid #f9f9f9;
      background-color: #eaebf2;
      box-shadow: none;

      &.header {
        background-color: #1d1e21;
        border-bottom: 1px dashed #cbcbcf;
        position: sticky;
        top: -1rem;
        z-index: 1;
      }

      &.fillColor td {
        background-color: #eaebf2;
      }

      &.active td {
        background-color: #dedfe2;
      }
    }

    & th,
    td {
      color: #202020;
      text-transform: capitalize;
      font-size: 1.8rem;
      border-right: ${({ noBorder }) => (noBorder ? '0px' : '1px dashed #cbcbcf')};
      border-radius: 0px !important;
      background-color: #eeeff5;
    }
  }



  td.groupHeading {
    border-bottom: 1px solid #cbcbcf;
    border-right: none;
  }

  & td.ai_left {
    text-align: left;
    padding: 2rem;
  }

  &.noBorder {
    & th,
    td {
      border-right: 0px solid;
      text-align: left;
    }
  }
`;
