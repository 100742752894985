import styled from 'styled-components';
import { device, minDevice } from '../../constants';
import { fontFamily } from '../../constants/font';
import { FlexContainer } from '../../globalStyles';

export const Container = styled.div`
  width: 90%;
  min-height: 35rem;
  border: 0.5px solid #a0a0a0;
  padding-bottom: 10px;

  & tspan {
    text-align: center;
    font-size: 1.8rem;
    font-family: ${fontFamily.segoeUiBold};
    letter-spacing: 1.8px;
    fill: #000000;
    opacity: 1;
  }

  & ul {
    display: none;
  }
`;

export const DropDownLabel = styled.div`
  font-size: 1.8rem;
  font-family: ${fontFamily.segoeUiBold};
  letter-spacing: 1.8px;
  color: #707070;
  opacity: 1;
  position: relative;
  left: 1rem;
  margin-bottom: 3px;
`;

export const Div = styled(FlexContainer)`
  justify-content: space-between;
  @media ${device.tablet} and ${minDevice.mobileS} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;

    & > .chartWrapper {
      margin-left: 2rem;
    }
  }
  &.buttom-space {
    margin-bottom: 3.5rem;
  }
`;

export const BarchartDiv = styled.div`
  & div.recharts-default-tooltip {
    > p {
      font-size: 2rem;
    }
  }

  & .keybuisnessmodule {
    text > tspan {
      fill: red !important;
    }
  }

  path.recharts-tooltip-cursor {
    fill: #f4f4f4;
  }
`;

export const AxisSpan = styled.span`
  font-size: 1.7rem;
  font-family: ${fontFamily.interBold};
  letter-spacing: 1.7px;
  color: #707070;

  &.keyBuisness {
    margin-top: 3rem;
  }
`;

export const LoaderDiv = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-item: center;
`;
