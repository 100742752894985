import { useEffect, useState } from 'react';

const useGraphWidthHeight = (chartBoxRef) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [dimesions, setDimensions] = useState({});

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    const boxWd = chartBoxRef?.current?.offsetWidth;
    let height;
    if (boxWd <= 425) {
      height = 250;
    } else if (windowWidth < 768 && windowWidth > 425) {
      height = 240;
    } else if (windowWidth > 768 && windowWidth <= 1440) {
      height = 315;
    } else if (windowWidth > 1440 && windowWidth < 1920) {
      height = 350;
    } else {
      height = 460;
    }
    setDimensions({
      width: boxWd,
      height: height
    });
  }, [windowWidth]);

  return { widthOf: dimesions.width, heightOf: dimesions.height };
};

export default useGraphWidthHeight;
