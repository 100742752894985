import React, { useRef, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import DropdownModal from '../../Components/Dropdown/DropdownModal';
import Loader from '../../Components/Loader/Loader';
import { fontFamily } from '../../constants/font';
import { FlexContainer, Span } from '../../globalStyles';
import useGraphWidthHeight from '../../shared/hooks/useGraphWidthHeight';
import { units } from '../../utility/constants';
import UnderlineHeader from '../../utility/underlineHeader';
import { ChartHeader, DropDownsWrap, Header, HeadingText, ToopltipP, Wrapper } from '../Overview/Overview.style';
import { BarchartDiv, Container, Div, DropDownLabel, LoaderDiv } from './Analysis.style';

const dataForDropDown = [
  { id: 1, label: 'Company 1', key: 'cmp1' },
  {
    id: 2,
    label: 'Company 2',
    key: 'cmp2'
  }
];

const HeadToHeadCompanrision = ({ plotData, loading }) => {
  const chartBoxRef = useRef();
  const { widthOf, heightOf } = useGraphWidthHeight(chartBoxRef);
  const [dropdownVal, setDropdownVal] = useState({});

  const options = plotData && Object.values(plotData);
  const { symbol: company1, company: cmp1Label } = dropdownVal?.cmp1 || options?.[0] || {};
  const { symbol: company2, company: cmp2Label } = dropdownVal?.cmp2 || options?.[1] || {};

  const details = [
    { label: 'Growth Rate', value: 'revenueGrowth' },
    { label: 'Revenue Multiple', value: 'enterpriseToRevenue' },
    { label: 'Gross Margin', value: 'grossMargins' }
  ];

  const data = details.map((e) => {
    const comp1Val = plotData?.[company1]?.[e.value];
    const comp2Val = plotData?.[company2]?.[e.value];
    let absComp1Val = comp1Val;
    let absComp2Val = comp2Val;
    // normalize to zero
    if (comp1Val < 0 && comp2Val > 0) {
      absComp1Val = -1 * comp1Val;
      absComp2Val = comp2Val + 2 * absComp1Val;
    } else if (comp1Val > 0 && comp2Val < 0) {
      absComp2Val = -1 * comp2Val;
      absComp1Val = comp1Val + 2 * absComp2Val;
    }
    return {
      [company1]: comp1Val,
      [company2]: comp2Val,
      [`${company1}abs`]: absComp1Val,
      [`${company2}abs`]: absComp2Val,
      name: e.label,
      key: e.value
    };
  });
  const handleDropdown = (item, property) => {
    setDropdownVal((prev) => ({
      ...prev,
      [property]: item
    }));
  };

  return (
    <Container>
      <Div>
        <Header className='space-between'>
          <ChartHeader style={{ width: 'auto' }}>
            <HeadingText>
              <UnderlineHeader header='Head to Head Comparison' noOfLastWords={1} />
            </HeadingText>
          </ChartHeader>
          <Wrapper className='chartWrapper'>
            {dataForDropDown &&
              dataForDropDown?.map((e, i) => {
                const isCmp1 = e.key === 'cmp1';
                return (
                  <FlexContainer key={e.id} style={{ position: 'relative', bottom: '4.2rem', alignItems: 'flex-end' }}>
                    <DropDownsWrap>
                      <DropDownLabel>{e.label}</DropDownLabel>
                      <DropdownModal
                        className={isCmp1 ? 'company1' : 'company2'}
                        data={options || []}
                        property={e.key}
                        setDropdownVal={handleDropdown}
                        label='company'
                        index={i}
                        header={isCmp1 ? cmp1Label : cmp2Label}
                        width={'45rem'}
                      />
                    </DropDownsWrap>
                    {i === 0 && <Span className='companyDivisor'>vs</Span>}
                  </FlexContainer>
                );
              })}
          </Wrapper>
        </Header>
      </Div>
      <BarchartDiv ref={chartBoxRef} style={{ marginTop: '2rem', position: 'relative', width: '96%', left: '2%' }}>
        {loading && (
          <LoaderDiv>
            <Loader />
          </LoaderDiv>
        )}
        <FlexContainer>
          {data.map((item, i) => (
            <BarChart
              width={widthOf / 3}
              height={widthOf > 768 ? heightOf - 25 : heightOf - 40}
              data={[item]}
              margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
              barGap={30}
              key={i} // can't add id here
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' tickMargin={17} />
              <YAxis hide={true} />
              <Tooltip
                wrapperStyle={{
                  outline: 'none',
                  background: '#ffffff 0% 0% no-repeat padding-box',
                  boxShadow: '0px 0px 25px #00000026',
                  border: '0.5px solid #a0a0a0',
                  padding: '5px 5px',
                  minWidth: 180
                }}
                content={<CustomTooltip plotData={plotData} />}
              />
              <Legend />
              <Bar dataKey={`${company1}abs`} fill='#819FB8' barSize={46} />
              <Bar dataKey={`${company2}abs`} fill='#384355' barSize={46} />
            </BarChart>
          ))}
        </FlexContainer>
        <FlexContainer className='jc_center' style={{ alignItems: 'flex-end' }}>
          <Box className='verses'>{dropdownVal.cmp1 ? `${dropdownVal?.cmp1?.company}` : '22nd Century Group, Inc'}</Box>
          <Box>&nbsp;&nbsp; vs &nbsp;&nbsp;</Box>
          <Box className='verses'> {dropdownVal.cmp2 ? ` ${dropdownVal?.cmp2?.company}` : ' 23andMe Holding Co.'}</Box>
        </FlexContainer>
      </BarchartDiv>
    </Container>
  );
};

export default HeadToHeadCompanrision;

export const Box = styled.span`
  font-size: 1.8rem;
  color: #808080;
  font-weight: bold;
  font-family: ${fontFamily.interVariableFont};
  &.verses {
    color: black;
  }
`;

function CustomTooltip({ payload, active }) {
  if (active) {
    return (
      <div className='custom-tooltip' style={{ padding: '1rem' }}>
        <ToopltipP style={{ color: '#AE0826' }} className='labelName'>
          {payload[0]?.payload?.name}
        </ToopltipP>

        {payload?.map((ele, i) => {
          return (
            <FlexContainer key={i} FlexContainer style={{ justifyContent: 'space-between' }}>
              <ToopltipP className='labelName'>{ele.name.replace('abs', '')}</ToopltipP>
              <span style={{ width: '4rem' }}>&nbsp;</span>
              <ToopltipP style={{ color: 'black' }} className='labelName'>
                {`${Number(ele.payload?.[ele.dataKey.replace('abs', '')]).toFixed(2)}${units?.[ele.payload.key]}`}
              </ToopltipP>
            </FlexContainer>
          );
        })}
      </div>
    );
  }

  return null;
}
