import { useState } from 'react';
import AnalysisStroke from '../assets/icons/Analysisf.svg';
import AnalysisSolid from '../assets/icons/AnalysisS.svg';
import ConstituentStroke from '../assets/icons/ConstituentsF.svg';
import ConstituentSolid from '../assets/icons/ConstituentsS.svg';
import OverviewStroke from '../assets/icons/OverviewF.svg';
import OverviewSolid from '../assets/icons/OverviewS.svg';
import { FlexContainer } from '../globalStyles';
import Analysis from '../Views/Analysis/Analysis.view';
import Constituents from '../Views/Constituents/Constituents.view';
import Overview from '../Views/Overview/Overview.view';
import { Li, Span, Ui } from './Routes.style';

const tabs = [
  { key: 3, label: 'overview' },
  { key: 2, label: 'analysis' },
  { key: 1, label: 'constituents' }
];
const Routes = () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const content = () => {
    switch (activeTab.label) {
      case 'constituents':
        return <Constituents />;
      case 'analysis':
        return <Analysis />;
      case 'overview':
        return <Overview setActiveTab={setActiveTab} tabs={tabs} />;
      default:
        break;
    }
  };
  return (
    <>
      <div>
        <Ui className='jc_center'>
          {tabs.map((tab) => {
            const { key, label } = tab;
            return (
              <Li
                key={key}
                disabled={activeTab?.label === `${label}`}
                onClick={() => setActiveTab(tab)}
                className='underline splitPoint'
              >
                <FlexContainer
                  style={{
                    gap: '2rem',
                    alignItems: 'center'
                  }}
                >
                  <div className='iconColor'>
                    {label === 'constituents' &&
                      (activeTab.label === 'constituents' ? (
                        <img src={ConstituentStroke} width='18px' height='16px' />
                      ) : (
                        <img src={ConstituentSolid} width='18px' height='16px' />
                      ))}
                    {label === 'analysis' &&
                      (activeTab.label === 'analysis' ? (
                        <img src={AnalysisStroke} width='21px' height='18px' />
                      ) : (
                        <img src={AnalysisSolid} width='21px' height='18px' />
                      ))}
                    {label === 'overview' &&
                      (activeTab.label === 'overview' ? (
                        <img src={OverviewStroke} width='18px' height='18px' />
                      ) : (
                        <img src={OverviewSolid} width='18px' height='18px' />
                      ))}
                  </div>
                  <Span className='uppercase'>{label}</Span>
                </FlexContainer>
              </Li>
            );
          })}
        </Ui>
      </div>
      {content()}
    </>
  );
};

export default Routes;
